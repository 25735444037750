
import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";
import { AddressComponent, CustomerAddress, GooglePlacesMap } from "@/shared/interfaces/GooglePlaces.interface";
import { computed, defineComponent, onMounted, onUnmounted, PropType, ref } from "@vue/composition-api";
import { getCountries } from "@/shared/helpers/localeHelpers";

export default defineComponent({
  name: "GooglePlacesAddressFieldGroup",
  props: {
    exisitingAddress: {
      type: Object as PropType<CustomerAddress>,
      required: false,
    },
  },
  setup(props, { emit }) {
    let addressAutocomplete: any;
    const address = ref<CustomerAddress | undefined>(deepCloneObject(props.exisitingAddress));

    const countryArr = getCountries();

    onMounted(() => {
      const addressAutocompleteInput = document.querySelector("#addressAutocomplete");
      // @ts-ignore - Må ignorere her, da den ikke vet hva google er.
      const addressAutocomplete = new window.google.maps.places.Autocomplete(
        /** @type {!HTMLInputElement} */ addressAutocompleteInput,
        {
          types: ["address"],
          fields: ["address_components"],
        }
      );

      // @ts-ignore - Må ignorere her, da den ikke vet hva google er.
      window.google.maps.event.addListener(addressAutocomplete, "place_changed", () => {
        const mapping: GooglePlacesMap = {
          street_number: "streetNumber",
          route: "postAddress1",
          postal_town: "postCity",
          postal_code: "postZip",
          country: "postCountry",
        };

        let place = { address_components: [], ...addressAutocomplete.getPlace() };

        address.value = place.address_components.reduce((acc: GooglePlacesMap, cur: AddressComponent) => {
          if (!mapping[cur.types[0] as keyof GooglePlacesMap]) {
            return acc;
          }
          return {
            ...acc,
            [mapping[cur.types[0] as keyof GooglePlacesMap]]: cur.short_name,
          };
        }, []);

        setTimeout(updateAddress, 50);
      });

      if (address.value?.postAddress1) {
        const splitAdr = address.value?.postAddress1 && address.value.postAddress1.split(/(\d+)/);
        address.value.streetNumber = splitAdr[1].trim() + splitAdr[2].trim();
        address.value.postAddress1 = splitAdr[0].trim();
      }
    });

    function containsNumbers(str?: string | null) {
      if (!str) {
        return false;
      }
      return /\d/.test(str);
    }

    const updateAddress = () => {
      if (!address.value) {
        return;
      }
      const adr = {
        ...address.value,
        postAddress1: address.value.postAddress1
          ? `${address.value.postAddress1.trim()} ${
              address.value.streetNumber ? address.value.streetNumber.trim() : ""
            }`
          : "",
      };
      delete adr.streetNumber;
      emit("updateAddress", adr);
    };

    onUnmounted(() => {
      if (addressAutocomplete) {
        // @ts-ignore - Må ignorere her, da den ikke vet hva google er.
        window.google.maps.event.clearInstanceListeners(addressAutocomplete);
      }
    });

    return {
      addressAutocomplete,
      address,
      countryArr,
      updateAddress,
      validateNotEmpty,
      displayStreetNumber: computed(
        () => !props.exisitingAddress?.streetNumber && !containsNumbers(address.value?.postAddress1)
      ),
    };
  },
});
