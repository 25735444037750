export const formatCurrency = (value?: number | null, currency = "NOK") => {
  if (!value) {
    return;
  }
  return new Intl.NumberFormat("no", { style: "currency", currency }).format(value);
};

export const isNumber = (evt: KeyboardEvent): void => {
  const keysAllowed: string[] = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "."];
  const keyPressed: string = evt.key;
  if (!keysAllowed.includes(keyPressed)) {
    evt.preventDefault();
  }
};
