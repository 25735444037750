var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "pa-0 ma-0"
  }, [_c('v-col', {
    staticClass: "pl-0 py-0",
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', _vm._b({
    ref: "dayRef",
    attrs: {
      "label": _vm.$t('profile.signupPage.day'),
      "placeholder": "DD",
      "hide-details": "",
      "maxlength": "2"
    },
    on: {
      "keypress": function ($event) {
        return _vm.isNumber($event);
      },
      "input": _vm.emitChanges
    },
    model: {
      value: _vm.day,
      callback: function ($$v) {
        _vm.day = $$v;
      },
      expression: "day"
    }
  }, 'v-text-field', Object.assign({}, _vm.$attrs), false))], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "3"
    }
  }, [_c('v-text-field', _vm._b({
    ref: "monthRef",
    attrs: {
      "outlined": "",
      "label": _vm.$t('profile.signupPage.month'),
      "placeholder": "MM",
      "maxlength": "2"
    },
    on: {
      "keypress": function ($event) {
        return _vm.isNumber($event);
      },
      "input": _vm.emitChanges
    },
    model: {
      value: _vm.month,
      callback: function ($$v) {
        _vm.month = $$v;
      },
      expression: "month"
    }
  }, 'v-text-field', Object.assign({}, _vm.$attrs), false))], 1), _c('v-col', {
    staticClass: "pr-0 py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-text-field', _vm._b({
    ref: "yearRef",
    attrs: {
      "outlined": "",
      "label": _vm.$t('profile.signupPage.year'),
      "placeholder": _vm.$t('profile.signupPage.yearPlaceholder'),
      "maxlength": "4",
      "rules": [].concat(_vm.validateParticipantMaxAge, _vm.validateParticipantMinAge)
    },
    on: {
      "keypress": function ($event) {
        return _vm.isNumber($event);
      },
      "input": _vm.emitChanges
    },
    model: {
      value: _vm.year,
      callback: function ($$v) {
        _vm.year = $$v;
      },
      expression: "year"
    }
  }, 'v-text-field', Object.assign({}, _vm.$attrs), false))], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }