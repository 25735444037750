
import { defineComponent, PropType } from "@vue/composition-api";
import { Route } from "vue-router";

export default defineComponent({
  name: "BaseIconCard",
  props: {
    icon: {
      type: String,
      required: false,
    },
    prependIcon: {
      type: String,
      required: false,
    },
    headline: {
      type: [String, Object], // Object type if translation (t("...")) is passed to headline
      required: true,
    },
    value: {
      type: [String, Object], // Object type if translation (t("...")) is passed to value
      required: true,
    },
    dataCy: {
      type: String,
      required: false,
      default: "",
    },
    to: {
      type: Object as PropType<Route | string>,
      required: false,
    },
    dense: {
      type: Boolean,
      required: false,
      default: false,
    },
    link: {
      type: Boolean,
      required: false,
      default: true,
    },
    captionAbove: {
      type: Boolean,
      required: false,
      default: false,
    },
    light: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["cardClick", "clickedPrependIcon"],
  setup(props, { emit }) {
    return {
      eventHandlers: props.link
        ? {
            click: () => {
              emit("cardClick");
            },
          }
        : {},
    };
  },
});
