import countries from "i18n-iso-countries";
import nbLocale from "i18n-iso-countries/langs/nb.json";
import enLocale from "i18n-iso-countries/langs/en.json";
import i18n from "@/plugins/i18n";

export const getCountries = () => {
  let locale = i18n.locale;

  if (locale === "no") {
    locale = "nb";
  }

  countries.registerLocale(nbLocale);
  countries.registerLocale(enLocale);

  const countryObj = countries.getNames(locale, { select: "official" });

  return Object.entries(countryObj).map(([key, value]) => {
    return {
      label: value,
      value: key,
    };
  });
};
