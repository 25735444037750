
import { isValidDate } from "@/shared/helpers/dateHelpers";
import { isNumber } from "@/shared/helpers/formattingHelpers";
import { validateParticipantMaxAge, validateParticipantMinAge } from "@/shared/helpers/validationHelpers";
import { computed, defineComponent, ref, watch } from "@vue/composition-api";
import { getDate, getYear, parse, parseISO } from "date-fns";
import formatISO from "date-fns/fp/formatISO/index";
import getMonth from "date-fns/fp/getMonth/index";
import isWithinInterval from "date-fns/isWithinInterval/index";

export default defineComponent({
  name: "BaseBirthDateInput",
  props: {
    birthDate: {
      type: String,
      required: false,
      default: "",
    },
    birthYear: {
      type: String,
      required: false,
    },
  },
  emits: ["dateChanged", "yearChanged"],
  setup(props, { emit }) {
    const dateOfBirth = computed(() =>
      isWithinInterval(parseISO(props.birthDate), {
        start: new Date(1900, 1, 1),
        end: new Date(),
      })
        ? parseISO(props.birthDate)
        : null
    );

    const day = ref(dateOfBirth.value ? getDate(dateOfBirth.value).toString() : "");
    const month = ref(dateOfBirth.value ? (getMonth(dateOfBirth.value) + 1).toString() : "");
    const year = ref(dateOfBirth.value ? getYear(dateOfBirth.value).toString() : props.birthYear || "");

    const dayRef = ref();
    const monthRef = ref();
    const yearRef = ref();

    const validateDayInput = (input: string) => (+input > 0 && +input <= 31) || "Fyll inn gyldig format";
    const validateMonthInput = (input: string) => (+input > 0 && +input <= 12) || "Fyll inn gyldig format";

    watch(day, () => {
      if (day.value.length === 2) {
        monthRef.value.focus();
      }
    });

    watch(month, () => {
      if (month.value.length === 2) {
        yearRef.value.focus();
      }
    });

    const emitChanges = () => {
      const isDateValid = isValidDate(day.value, month.value, year.value);
      if (isDateValid) {
        emit("dateChanged", formatISO(parse(`${day.value}/${month.value}/${year.value}`, "dd/MM/yyyy", new Date())));
      } else {
        emit("dateChanged", undefined);
      }

      if (year.value.length === 4) {
        emit("yearChanged", year.value);
      }
    };

    return {
      isNumber,
      validateDayInput,
      validateMonthInput,
      emitChanges,
      month,
      year,
      day,
      dayRef,
      monthRef,
      yearRef,
      dateOfBirth,
      validateParticipantMaxAge,
      validateParticipantMinAge,
    };
  },
});
