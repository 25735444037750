import { deepCloneObject } from "@/shared/helpers/deepCloneHelpers";
import { ref, toRefs, watch } from "@vue/composition-api";

export const useAutoDeepCloneProp = <RefType>(props: any, propName: string) => {
  const propsRef = toRefs(props);
  const myRef = ref<RefType>(deepCloneObject(propsRef[propName].value));

  watch(
    propsRef[propName],
    () => {
      myRef.value = deepCloneObject(propsRef[propName].value);
    },
    { deep: true }
  );

  return myRef;
};
