var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "8"
    }
  }, [_c('v-text-field', {
    attrs: {
      "id": "addressAutocomplete",
      "type": "text",
      "outlined": "",
      "label": _vm.$t('profile.signupPage.streetName'),
      "hide-details": ""
    },
    on: {
      "input": _vm.updateAddress
    },
    model: {
      value: _vm.address.postAddress1,
      callback: function ($$v) {
        _vm.$set(_vm.address, "postAddress1", $$v);
      },
      expression: "address.postAddress1"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "outlined": "",
      "hide-details": "",
      "label": _vm.$t('profile.signupPage.streetNumber')
    },
    on: {
      "input": _vm.updateAddress
    },
    model: {
      value: _vm.address.streetNumber,
      callback: function ($$v) {
        _vm.$set(_vm.address, "streetNumber", $$v);
      },
      expression: "address.streetNumber"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "outlined": "",
      "hide-details": "",
      "label": ((_vm.$t('profile.signupPage.postCode')) + " *"),
      "rules": [_vm.validateNotEmpty]
    },
    on: {
      "input": _vm.updateAddress
    },
    model: {
      value: _vm.address.postZip,
      callback: function ($$v) {
        _vm.$set(_vm.address, "postZip", $$v);
      },
      expression: "address.postZip"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "8"
    }
  }, [_c('v-text-field', {
    attrs: {
      "type": "text",
      "outlined": "",
      "hide-details": "",
      "label": ((_vm.$t('profile.signupPage.postLocation')) + " *"),
      "rules": [_vm.validateNotEmpty]
    },
    on: {
      "input": _vm.updateAddress
    },
    model: {
      value: _vm.address.postCity,
      callback: function ($$v) {
        _vm.$set(_vm.address, "postCity", $$v);
      },
      expression: "address.postCity"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-autocomplete', {
    attrs: {
      "type": "text",
      "items": _vm.countryArr,
      "item-text": "label",
      "item-value": "value",
      "outlined": "",
      "hide-details": "",
      "label": ((_vm.$t('profile.signupPage.country')) + " *"),
      "rules": [_vm.validateNotEmpty]
    },
    on: {
      "input": _vm.updateAddress
    },
    model: {
      value: _vm.address.postCountry,
      callback: function ($$v) {
        _vm.$set(_vm.address, "postCountry", $$v);
      },
      expression: "address.postCountry"
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }